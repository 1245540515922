import { Injectable } from '@angular/core';
import { UserInPing } from '@reach/interfaces';
import { BaseApiService } from '~/api/_internal';

@Injectable({ providedIn: 'root' })
export class SessionApiService extends BaseApiService {
	protected commonPath = ['auth'];

	public async ping(): Promise<UserInPing> {
		const params = { t: this.paramTime };

		const res = await this.http.get<UserInPing>(this.url('ping'), params);
		return res;
	}

	public async login(username: string, password: string): Promise<UserInPing> {
		const body = {
			username,
			password,
		};

		const res = await this.http.post<UserInPing>(this.url('login'), body);
		return res;
	}

	public async logout(): Promise<void> {
		await this.http.post(this.url('logout'), {});
	}
}
