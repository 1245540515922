import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AdminFeatureFlags } from '@reach/interfaces';
import { EmptyWithOutletPageComponent } from '~/core/pages';
import { SessionService } from '~/session/services';

export const rootRoutes: Routes = [
	{
		path: '',
		component: EmptyWithOutletPageComponent,
		children: [
			{
				// LOGIN
				path: '',
				canMatch: [
					async () => {
						const sessionService = inject(SessionService);
						const role = await sessionService.getAdminRole();
						return role === null;
					},
				],
				loadComponent: () => import('~/auth/page').then((m) => m.AuthPageComponent),
			},
			{
				// NO ACCESS
				path: '',
				canMatch: [
					async () => {
						const sessionService = inject(SessionService);
						const role = await sessionService.getAdminRole();
						return role === null || role! >= AdminFeatureFlags.USER;
					},
				],
				loadComponent: () =>
					import('~/no-access/page').then((m) => m.NoAccessPageComponent),
			},
			{
				path: '',
				loadChildren: () => import('~/home/router').then((m) => m.HomeRouterModule),
			},
		],
	},
];
