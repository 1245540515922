import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { REACH_COMMIT_HASH, REACH_VERSION } from '~/core/tokens';
import { BrowserThemeReactor } from '~/root/reactors';

@Component({
	selector: 'admin-tools-client',
	template: `
		<browser-theme-reactor></browser-theme-reactor>
		<router-outlet></router-outlet>
		<p
			id="main-version"
			class="pos-abs pos-b-0 pos-r-0 p-0h fs-xs fc-400 fw-bold"
			style="z-index: 999; opacity: 0.75; pointer-events: none;"
		>
			v{{ version }}.{{ commit }}
		</p>
	`,
	styles: [':host{ display: block; width: 100%; height: 100%; }'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterModule, BrowserThemeReactor],
	host: {
		class: 'pos-rel',
	},
})
export class RootPageComponent {
	public readonly version = inject(REACH_VERSION);
	public readonly commit = inject(REACH_COMMIT_HASH);
}
