import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
	BUILD_TIME,
	CLIENT_ENVIRONMENT,
	CommitHash,
	ReachVersion,
	REACH_COMMIT_HASH,
	REACH_VERSION,
} from '~/core/tokens';
import { ClientEnvironmentName } from '~/core/types';
import { environment } from '~/env';
import { rootRoutes } from '~/root/router';

declare const PACKAGE_VERSION: ReachVersion;
declare const COMMIT_HASH: CommitHash;
declare const WEBPACK_BUILD_TIME: number;

export const getRootProviders = () => {
	const properties = {
		packageVersion: PACKAGE_VERSION,
		commitHash: COMMIT_HASH,
		buildTime: WEBPACK_BUILD_TIME,
		envName: environment.environmentName,
	};

	if (environment.environmentName !== ClientEnvironmentName.PRODUCTION) {
		console.table(properties);

		console.log(environment);
	}

	return [
		provideRouter(
			rootRoutes,
			withRouterConfig({
				onSameUrlNavigation: 'reload',
				paramsInheritanceStrategy: 'always',
			})
		),
		provideAnimations(),
		provideHttpClient(),
		{ provide: BUILD_TIME, useValue: BUILD_TIME },
		{ provide: REACH_VERSION, useValue: PACKAGE_VERSION },
		{ provide: REACH_COMMIT_HASH, useValue: COMMIT_HASH },
		{ provide: CLIENT_ENVIRONMENT, useValue: environment },
	];
};
