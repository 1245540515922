import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

const HTML_THEME_ATTR = 'browser-theme';

@UntilDestroy()
@Component({
	selector: 'browser-theme-reactor',
	template: '',
	standalone: true,
	host: {
		class: 'd-none',
	},
})
export class BrowserThemeReactor implements OnInit {
	private readonly document = inject(DOCUMENT);
	private readonly renderer = inject(Renderer2);

	async ngOnInit(): Promise<void> {
		const prefersDark =
			window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
		const theme = prefersDark ? 'dark' : 'light';

		this.setTheme(theme);

		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
			const newColorScheme = event.matches ? 'dark' : 'light';
			this.setTheme(newColorScheme);
		});
	}

	private setTheme(theme: 'dark' | 'light' | null): void {
		this.renderer.setAttribute(this.document.body.parentElement, HTML_THEME_ATTR, theme ?? '');
	}
}
