import { UserInPing } from '@reach/interfaces';
import { ReachSession } from '~/session/types';

export function apiUserParser(user: UserInPing | null): ReachSession | null {
	if (!user) {
		return null;
	}

	return {
		userId: user.privateId,
		username: user.username,
		adminRole: user.adminFeatureFlags,
	};
}
