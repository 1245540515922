import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-page-container',
	template: '',
	styles: [':host{ display: block; width: 100%; height: 100%; }'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class EmptyPageComponent {}
