import { Injectable } from '@angular/core';
import { SettingsResponse } from '@reach/interfaces';
import { BaseApiService } from '~/api/_internal';

@Injectable({ providedIn: 'root' })
export class SettingsApiService extends BaseApiService {
	protected commonPath = ['settings'];

	public async getSettings(): Promise<SettingsResponse> {
		const params = { t: this.paramTime };
		const res = await this.http.get<SettingsResponse>(this.url(''), params);
		return res;
	}
}
