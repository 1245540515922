import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminFeatureFlags, UserInPing } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { apiUserParser } from '~/session/parsers';
import { ReachSession } from '~/session/types';
import { SessionApiService } from './session.api.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
	private readonly sessionApi = inject(SessionApiService);
	private readonly router = inject(Router);

	private sessionChecked = false;

	private readonly user$$ = new BehaviorSubject<ReachSession | null>(null);
	public readonly user$ = this.user$$.asObservable();

	public async getAdminRole(): Promise<AdminFeatureFlags | null> {
		if (!this.sessionChecked) {
			const res = await this.sessionApi.ping().catch(() => null);
			this.setUser(res);
			this.sessionChecked = true;
		}

		return this.user$$.value?.adminRole ?? null;
	}

	public async login(username: string, password: string): Promise<void> {
		const res = await this.sessionApi.login(username, password);
		this.setUser(res);
		this.sessionChecked = true;
		await this.router.navigate(['/']);
	}

	public async logout(): Promise<void> {
		try {
			await this.sessionApi.logout();
			this.sessionChecked = false;
		} catch (error) {
			console.error(error);
		}
		this.setUser(null);
		await this.router.navigate(['/']);
	}

	private setUser(user: UserInPing | null): void {
		this.user$$.next(apiUserParser(user));
	}
}
