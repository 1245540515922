import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { ClientEnvironmentName } from '~/core/types';
import { environment } from 'environments/environment';
import { RootPageComponent } from '~/root/page';
import { getRootProviders } from '~/root/providers';

try {
	if (environment.environmentName === ClientEnvironmentName.PRODUCTION) {
		enableProdMode();
	}

	bootstrapApplication(RootPageComponent, {
		providers: getRootProviders(),
	});
} catch (error) {
	console.error(error);
}
