import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'app-page-container-with-outlet',
	template: '<router-outlet></router-outlet>',
	styles: [':host{ display: block; width: 100%; height: 100%; }'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterModule],
})
export class EmptyWithOutletPageComponent {}
