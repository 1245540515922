export enum ClientEnvironmentName {
	PRODUCTION = 'PRODUCTION',
	STAGING = 'STAGING',
    REPLICA = 'REPLICA',
	DEV = 'DEV',
	LOCAL = 'LOCAL',
}

export interface ClientEnvironment {
	environmentName: ClientEnvironmentName;
	clientUrl: string;
	apiUrl: string;
}
